import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "widget10",
  key: "widget10",
  ref: "maximizeModule",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px  Ralewayfont" }
const _hoisted_3 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget10 = _resolveComponent("widget10")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createCommentVNode("begin::Modal content"),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_widget10, { style: {"height":"400px"} }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "widget10")
          ]),
          _: 3 /* FORWARDED */
        })
      ])
    ])
  ], 512 /* NEED_PATCH */))
}