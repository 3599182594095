
import { defineComponent, onMounted, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Globals from "@/views/common/Globals.vue";
import MaxMinWidget2 from "./MaxMinWidget2.vue"
import alasql from "alasql";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "widget-2",
  mixins: [Globals],
  components: {
    MaxMinWidget2
  },
  data() {
    return {
      APIData: this.apiData,
      startDate: "",
      endDate: "",
      currentDate: "",
      monthYear: [] as any,
      presentYear: "",
      ApiCalls: [] as any,
      chartOptions: {
        chart: {
          type: "line",
          height: 200,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        grid: {
          show: false,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
            }
          },
          yaxis: {
            lines: {
              show: false  //or just here to disable only y axis
            }
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            borderRadius: 10
          },
         
        },
        dataLabels: {
            enabled: false
          },
        xaxis: {
          categories: [] as any,
          labels: {
            rotate: -45,
            rotateAlways: true,
          }
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        colors: ['#febc3b'],
      },
      render: false,
      currentMonth: "",
      currentYear: "",
    };
  },
  methods: {
    display() {
      this.render = true;
      console.log("API Dtata in widget2",this.APIData)
      this.chartOptions.xaxis.categories = this.monthYear;
      let organizationData = alasql("SELECT * FROM ? ", [this.APIData]);
      console.log("Tis will bw alasql", organizationData,this.APIData)
      let initialData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < organizationData.length; i++) {
        let mY = "";
        mY =
          organizationData[i]["Month"] +
          "-" +
          organizationData[i]["Year"].toString().slice(2, 4);
        if (this.monthYear.indexOf(mY) != -1) {
          for (let j = 0; j < this.monthYear.length; j++) {
            if (this.monthYear[j] == mY) {
              initialData[j] = initialData[j] + organizationData[i]["Calls"];
              break;
            }
          }
        }
      }
      let body = {};
      body["name"] = "API Calls";
      body["data"] = initialData;
      this.ApiCalls.push(body);
    },
      calculateDate() {
        let current_date = new Date();
        if (new Date().getDate() == 1) {
            current_date = new Date(new Date().setDate(new Date().getDate() - 1));
        }
          let current = current_date.getFullYear();
          let month = current_date.getMonth();
          let todayDate = current_date;
      this.endDate = todayDate
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];
      this.currentDate = this.endDate;
          var d = current_date;
      d.setDate(1);
      for (let i = 0; i < 11; i++) {
        d.setMonth(d.getMonth() - 1);
      }
      this.startDate = d
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];
      if (month == 0) {
        // current = current;
        this.presentYear = current.toString();
      }
      // if (month - 1 < 0) (month = 11), (this.presentYear = current.toString());
      // else {
      //   this.presentYear = current.toString();
      // }

      let allMonths = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      for (let i = 0; i < 12; i++) {
        this.monthYear.push(
          allMonths[month] + "-" + current.toString().slice(2, 4)
        );
        month--;
        if (month < 0) {
          current = current - 1;
          month = 11;
        }
      }
      this.monthYear = this.monthYear.reverse()
    },
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
    apiData: Array,
  },
  mounted() {
    this.calculateDate();
    this.display();
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const color = getCSSVariableValue("--bs-" + props.widgetColor);
    const strokeColor = ref(props.strokeColor);
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
});
