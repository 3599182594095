import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 2,
  class: "col col-lg-4 pb-5 pb-xl-8"
}
const _hoisted_3 = {
  key: 4,
  class: "col col-lg-4 pb-5 pb-xl-8"
}
const _hoisted_4 = {
  key: 5,
  class: "col col-lg-4 pb-5 pb-xl-8"
}
const _hoisted_5 = {
  key: 6,
  class: "col col-lg-4 pb-5 pb-xl-8"
}
const _hoisted_6 = {
  key: 7,
  class: "col col-lg-4 pb-5 pb-xl-8"
}
const _hoisted_7 = {
  key: 8,
  class: "col col-lg-4 pb-5 pb-xl-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget2 = _resolveComponent("MixedWidget2")!
  const _component_MixedWidget10 = _resolveComponent("MixedWidget10")!
  const _component_MixedWidget11 = _resolveComponent("MixedWidget11")!
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")!
  const _component_ManufacturerApiStatus = _resolveComponent("ManufacturerApiStatus")!
  const _component_OneSBGatewayAPIStatus = _resolveComponent("OneSBGatewayAPIStatus")!
  const _component_PlatformInternalAPI = _resolveComponent("PlatformInternalAPI")!
  const _component_ConsumerAPIStatus = _resolveComponent("ConsumerAPIStatus")!
  const _component_ThirdPartyAPIStatus = _resolveComponent("ThirdPartyAPIStatus")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createCommentVNode("begin::Dashboard"),
    _createVNode("div", _hoisted_1, [
      (_ctx.render)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: ["col pb-5 pb-xl-8", _ctx.getOrgRole() == 'manufacturer' ? 'col-lg-6' : 'col-lg-4']
          }, [
            _createVNode(_component_MixedWidget2, {
              "widget-classes": "card-xxl-stretch-50",
              "widget-color": "light-success",
              "stroke-color": "#3598DC",
              apiData: this.ApiData
            }, null, 8 /* PROPS */, ["apiData"]),
            _createCommentVNode(" <MixedWidget12\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget12>\n\n\n\n       ")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Audit log "),
      (_ctx.render)
        ? (_openBlock(), _createBlock("div", {
            key: 1,
            class: ["col pb-5 pb-xl-8", _ctx.getOrgRole() == 'manufacturer' ? 'col-lg-6' : 'col-lg-4']
          }, [
            _createCommentVNode(" by product group "),
            _createVNode(_component_MixedWidget10, {
              "widget-classes": "card-xxl-stretch-50",
              "chart-color": "primary",
              "chart-height": "180",
              apiData: this.ApiData
            }, null, 8 /* PROPS */, ["apiData"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.render || (_ctx.getOrgRole() != 'manufacturer' && _ctx.ApiData.length != 0))
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_ctx.getOrgRole() != 'manufacturer')
              ? (_openBlock(), _createBlock(_component_MixedWidget11, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  apiData: this.ApiData
                }, null, 8 /* PROPS */, ["apiData"]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.render)
        ? (_openBlock(), _createBlock("div", {
            key: 3,
            class: ["col pb-5 pb-xl-8", _ctx.getOrgRole() != '1SB' ? 'col-12' : 'col-lg-12']
          }, [
            _createCommentVNode(" by Manufacturer "),
            _createVNode(_component_MixedWidget7, {
              "widget-classes": "card-xxl-stretch-50",
              "chart-color": "primary",
              "chart-height": "180",
              apiData: this.ApiData
            }, null, 8 /* PROPS */, ["apiData"]),
            _createCommentVNode(" widget-classes=\"card-xl-stretch mb-2 mb-xl-1\" ")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" <div v-if=\"render || getOrgRole() != 'manufacturer' && ApiData.length!=0\" class=\"col col-lg-4 pb-5 pb-xl-8\">\n      <MixedWidget11 v-if=\"getOrgRole() != 'manufacturer'\" widget-classes=\"card-xxl-stretch-50\" chart-color=\"primary\" chart-height=\"180\"\n        :apiData=\"this.ApiData\"></MixedWidget11>\n    </div> "),
      ((_ctx.render && _ctx.getOrgRole() == 'consumer') || _ctx.getOrgRole() == '1SB')
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createCommentVNode(" product group "),
            (_ctx.getOrgRole() === 'consumer' || _ctx.getOrgRole() == '1SB')
              ? (_openBlock(), _createBlock(_component_ManufacturerApiStatus, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  manufactureList: _ctx.manufactureApis
                }, null, 8 /* PROPS */, ["manufactureList"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <MixedWidget9  v-if=\"getOrgRole() === 'manufacturer'  || getOrgRole() === '1SB'\"\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget9> ")
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.render && _ctx.getOrgRole() == 'consumer' || _ctx.getOrgRole() == '1SB')
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createCommentVNode(" product group "),
            (_ctx.getOrgRole() == '1SB' || _ctx.getOrgRole() == 'consumer')
              ? (_openBlock(), _createBlock(_component_OneSBGatewayAPIStatus, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  apiList: _ctx.platFormApis
                }, null, 8 /* PROPS */, ["apiList"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <MixedWidget9  v-if=\"getOrgRole() === 'manufacturer'  || getOrgRole() === '1SB'\"\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget9> ")
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.render && _ctx.getOrgRole() == '1SB')
        ? (_openBlock(), _createBlock("div", _hoisted_5, [
            _createCommentVNode(" product group "),
            (_ctx.getOrgRole() == '1SB')
              ? (_openBlock(), _createBlock(_component_PlatformInternalAPI, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  apiList: _ctx.platFormApis
                }, null, 8 /* PROPS */, ["apiList"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <MixedWidget9  v-if=\"getOrgRole() === 'manufacturer'  || getOrgRole() === '1SB'\"\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget9> ")
          ]))
        : _createCommentVNode("v-if", true),
      ((_ctx.render && _ctx.getOrgRole() == 'consumer') || _ctx.getOrgRole() == '1SB')
        ? (_openBlock(), _createBlock("div", _hoisted_6, [
            _createCommentVNode(" product group "),
            (_ctx.getOrgRole() === 'consumer' || _ctx.getOrgRole() == '1SB')
              ? (_openBlock(), _createBlock(_component_ConsumerAPIStatus, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  apiList: _ctx.consumerApis
                }, null, 8 /* PROPS */, ["apiList"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <MixedWidget9  v-if=\"getOrgRole() === 'manufacturer'  || getOrgRole() === '1SB'\"\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget9> ")
          ]))
        : _createCommentVNode("v-if", true),
      ((_ctx.render && _ctx.getOrgRole() == 'consumer') || _ctx.getOrgRole() == '1SB')
        ? (_openBlock(), _createBlock("div", _hoisted_7, [
            _createCommentVNode(" product group "),
            (_ctx.getOrgRole() === 'consumer' || _ctx.getOrgRole() == '1SB')
              ? (_openBlock(), _createBlock(_component_ThirdPartyAPIStatus, {
                  key: 0,
                  "widget-classes": "card-xxl-stretch-50",
                  "chart-color": "primary",
                  "chart-height": "180",
                  apiList: _ctx.thirdPartyApis
                }, null, 8 /* PROPS */, ["apiList"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <MixedWidget9  v-if=\"getOrgRole() === 'manufacturer'  || getOrgRole() === '1SB'\"\n        widget-classes=\"card-xxl-stretch-50\"\n        chart-color=\"primary\"\n        chart-height=\"180\"\n      ></MixedWidget9> ")
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}