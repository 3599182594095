
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import alasql from "alasql";
import MaxMinWidget10 from "./MaxMinWidget10.vue";

export default defineComponent({
    name: "widget-12",
    mixins: [Globals],
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        apiData: Array
    },
    data() {
        return {
            render: false,
            APIData: this.apiData,
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 180,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                grid: {
                    show: false, // you can either change hear to disable all grids
                    xaxis: {
                        lines: {
                            show: false //or just here to disable only x axis grids
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false //or just here to disable only y axis
                        }
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            // legend: {
                            //     position: "bottom",
                            //     offsetX: -10,
                            //     offsetY: 0,
                            // },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        borderRadius: 10,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    //  type: 'datetime',
                    // categories: ["Life Term", "ULIP", "Motor", "Health", "Endowment"],
                    categories: [] as any,
                },
                legend: {
                    show: false,
                //   position: 'right',
                //   offsetY: 40
                },
                fill: {
                    opacity: 1,
                },
                colors: ['#68d4cd'],
            },
            productApiCalls: [] as any,
            currentYear: "",
            currentMonth: "",
        };
    },
    methods: {
        display() {
            this.render = true;
            // this.APIData = JSON.parse(JSON.stringify(data));
            let distinctProductData = alasql("SELECT DISTINCT [Product Group] FROM ?", [
                this.APIData,
            ]);
            let monthYearData = alasql("SELECT * FROM ? WHERE Year==? AND  Month==?", [this.APIData, Number(this.currentYear), this.currentMonth]);
            //  console.log("month",this.chartOptions.xaxis.categories,distinctProductData.length)
            let apiCalls = [] as any;
            let apiData = [] as any;
            for (let i = 0; i < distinctProductData.length; i++) {
                if(distinctProductData[i]["Product Group"]!= null)
                {
                    this.chartOptions.xaxis.categories.push(distinctProductData[i]["Product Group"]);
                    apiData = alasql("SELECT * FROM ? WHERE [Product Group]==?", [monthYearData, distinctProductData[i]["Product Group"]]);
                
                }
              let calls = 0;
                for (let j = 0; j < apiData.length; j++) {
                    calls = calls + apiData[j]['Calls'];
                }
                apiCalls.push(calls);
            }
            let body = {};
            body["name"] = "API Calls";
            body["data"] = apiCalls;
            this.productApiCalls.push(body);
        },
        calculateDate() {
            let current_date = new Date();
            if (new Date().getDate() == 1) {
                current_date = new Date(new Date().setDate(new Date().getDate() - 1));
            }
            let current = current_date.getFullYear();
            let month = current_date.getMonth();
            this.currentYear = current.toString();
            let array = [
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
            ];
            this.currentMonth = array[month];
            //  console.log(this.currentMonth,this.currentYear)
        },
    },
    mounted() {
        this.calculateDate();
        this.display();
    },
    setup(props) {
        const color = ref(props.chartColor);
        const labelColor = getCSSVariableValue("--bs-gray-500");
        const borderColor = getCSSVariableValue("--bs-gray-200");
        const secondaryColor = getCSSVariableValue("--bs-gray-300");
        const baseColor = getCSSVariableValue("--bs-" + color.value);
    },
    components: { MaxMinWidget10 }
});
