
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import alasql from "alasql";
import MaxMinWidget11 from "./MaxMinWidget11.vue";
export default defineComponent({
    name: "widget-11",
    mixins: [Globals],
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        apiData: Array
    },
    data() {
        return {
            render: false,
            APIData: this.apiData,
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 180,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                grid: {
                    show: false, // you can either change hear to disable all grids
                    xaxis: {
                        lines: {
                            show: false //or just here to disable only x axis grids
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false //or just here to disable only y axis
                        }
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        columnWidth: '30%',
                        horizontal: false,
                        borderRadius: 10,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    //  type: 'datetime',
                    // categories: ["Insurance", "PMS"],
                    categories: [] as any,
                },
                // legend: {
                //   position: 'right',
                //   offsetY: 40
                // },
                fill: {
                    colors: ["#8aa5d0", "#dea4cc"],
                    opacity: 1,
                },
            },
            categoryApi: {
                CBI: {
                    "2021": {
                        summary: {
                            categories: ["INSURANCE", "PMS"],
                            series: [300, 250],
                        },
                        "month-wise": {
                            JAN: {
                                categories: ["INSURANCE", "PMS"],
                                series: [60, 100],
                            },
                            FEB: {
                                categories: ["INSURANCE", "PMS"],
                                series: [65, 110],
                            },
                        },
                    },
                },
            },
            categoryApiCalls: [] as any,
            series: [
                {
                    name: "API Call",
                    // data: [60,100],
                    data: [],
                },
            ],
            currentYear: "",
            currentMonth: "",
        };
    },
    methods: {
        display() {
            this.render = true;
            // this.APIData = JSON.parse(JSON.stringify(data));
            let distinctCategoryData = alasql("SELECT DISTINCT [Category] FROM ?", [
                this.APIData,
            ]);
            let monthYearData = alasql("SELECT * FROM ? WHERE Year==? AND  Month==?", [this.APIData, Number(this.currentYear), this.currentMonth]);
            let apiCalls = [] as any;
            let apiData = []  as any;
            for (let i = 0; i < distinctCategoryData.length; i++) {
              if(distinctCategoryData[i]["Category"] != null)
              {
                this.chartOptions.xaxis.categories.push(distinctCategoryData[i]["Category"]);
                 apiData = alasql("SELECT * FROM ? WHERE [Category]==?", [monthYearData, distinctCategoryData[i]["Category"]]);
               
              }
                 let calls = 0;
                for (let j = 0; j < apiData.length; j++) {
                    calls = calls + apiData[j]['Calls'];
                }
                apiCalls.push(calls);
            }
            let body = {};
            body["name"] = "API Calls";
            body["data"] = apiCalls;
            this.categoryApiCalls.push(body);
        },
        calculateDate() {
            let current_date = new Date();
            if (new Date().getDate() == 1) {
                current_date = new Date(new Date().setDate(new Date().getDate() - 1));
            }
            let current = current_date.getFullYear();
            let month = current_date.getMonth();
            this.currentYear = current.toString();
            let array = [
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
            ];
            this.currentMonth = array[month];
        },
    },
    mounted() {
        this.calculateDate();
        this.display();
    },
    setup(props) {
        const color = ref(props.chartColor);
        const labelColor = getCSSVariableValue("--bs-" + "gray-800");
        const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
        const baseColor = getCSSVariableValue("--bs-" + color.value);
        const lightColor = getCSSVariableValue("--bs-light-" + color.value);
    },
    components: { MaxMinWidget11 }
});
