
import { defineComponent, ref } from "vue";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import alasql from "alasql";


import Datatable from "@/components/kt-datatable/Datatable.vue";
export default defineComponent({
  name: "ConsumerAPIStatus",
  mixins: [Globals],
  components: {
    Datatable,
  },
  props: {
    widgetClasses: String,
    color: String,
    image: String,
    title: String,
    date: String,
    progress: String,
    apiList: []
  },
  data() {
    return {
      ApiData: [],
      consumerData: [],
      orgData: [] as any,
      page: 1,
      totalCount:1,
      x: '' as any,
    }
  },
  methods: {
    // smtg(x){
    //   this.y=x.type === 'mouseover'
    // },
    fun(x) {
      this.x = x
    },
    display(data) {
      this.ApiData = JSON.parse(JSON.stringify(data));
      for (let i of Object.keys(this.ApiData["data"])) {
        if (Object.prototype.hasOwnProperty.call(this.ApiData, "data") && (this.ApiData["data"][i] != null)) {// condition if data is not null
          this.orgData.push(this.ApiData["data"][i])
        }
      }
    },
    getOrganizations() {
      if (this.totalCount != this.consumerData.length) {
        ApiService.get(`/organizations?organizationType=C&pageNumber=${this.page}&pageSize=5`)
          .then((data) => {
            this.totalCount = data.data.data.total_count;
            return data.data.data.organization_list.map((item, index) => ApiService.get(`/organizations/${item.id}/apis?featureMode=dashboard_api_status`)
              .then((data) => {

                const status = alasql('SELECT COLUMN DISTINCT latestStatus FROM ?', [data.data.data.api_list]);
                const lastUpdatedTime = alasql('SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC', [data.data.data.api_list]);


                if (status.length > 1) {
                  status.splice(0, status.length, "partial")
                } else if (status[0] === undefined) {
                  status.splice(0, status.length, "NA")

                }
                this.consumerData.push({ name: item.name, latestStatus: status[0], lastStatusChangeDate: lastUpdatedTime[0] != undefined ? lastUpdatedTime[0] : "Not Availabel" })
  

              })
              .catch((error) => {
                console.log("Error IN manufacture APi", error)
              }))

          }).
          catch((error) => {
            console.log("Error In side Organization", error)
          })
      }

      this.page += 1;
    },
    handleScroll(e) {

      const element = document.getElementById("consumerCard");
      var style = window.getComputedStyle(element, null);
      const heightElement = style.getPropertyValue("height")
      if (parseInt(style.getPropertyValue("height").split("p")[0]) + element.scrollTop + 10 >= element.scrollHeight) {
        this.getOrganizations();

      }
    },
    // getManuData() {
    //   ApiService.get("/manufacturerStatus/Dashboard?organizationId=" + this.globalData.organizationId)
    //     .then((data) => {
    //       this.display(data.data)
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    // }
  },
  mounted() {
    // this.getManuData();
    this.getOrganizations();
    
    const element = document.getElementById("consumerCard");
 
    element.addEventListener("scroll", this.handleScroll)

  },
  destroy() {

    const element = document.getElementById("consumerCard");
    element.removeEventListener("scroll", this.handleScroll)
  

  },

  setup() {
    // const text = document.getElementById('text')
    // text.onmouseover =function(id){
    //   text.innerHTML ="skjvnsvj"
    // }

    const tableHeaderConsumer = ref([
      {
        name: "Consumer Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Status",
        key: "latestStatus",
        sortable: true,
      },

    ]);
    const tableHeaderManufacturer = ref([
      {
        name: "Consumer Name",
        key: "consumerId",
        sortable: true,
      },

      {
        name: "Api Status",
        key: "latestStatus",
        sortable: true,
      },

    ]);
    return {
      tableHeaderConsumer,
      tableHeaderManufacturer
    }
  }
});


