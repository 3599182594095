
import { defineComponent, onMounted } from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import ManufacturerApiStatus from "@/components/widgets/mixed/ManufacturerApiStatus.vue";
import OneSBGatewayAPIStatus from "@/components/widgets/mixed/OneSBGatewayAPI Status.vue";
import ConsumerAPIStatus from "@/components/widgets/mixed/ConsumerAPIStatus.vue";
import ThirdPartyAPIStatus from "@/components/widgets/mixed/ThirdPartyAPIStatus.vue";
import PlatformInternalAPI from "@/components/widgets/mixed/Platform&InternalAPI.vue";
// import MixedWidget9 from "@/components/widgets/mixed/Widget9.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import MixedWidget11 from "@/components/widgets/mixed/Widget11.vue";
// import MixedWidget12 from "@/components/widgets/mixed/Widget12.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading, ElNotification } from "element-plus";
import router from "@/router";
import store from "@/store";
import alasql from "alasql";
export default defineComponent({
  name: "dashboard",
  mixins: [Globals],
  components: {
    MixedWidget2,
    ManufacturerApiStatus,
    OneSBGatewayAPIStatus,
    ConsumerAPIStatus,
    ThirdPartyAPIStatus,
    PlatformInternalAPI,
    // MixedWidget9,
    MixedWidget7,
    MixedWidget10,
    MixedWidget11,
    // MixedWidget12,
  },
  data() {
    return {
      errorPart: "",
      currentYear: "",
      currentMonth: "",
      presentYear: "",
      startDate: "",
      endDate: "",
      ApiData: [] as any,
      render: false,
      manufacturer: [],
      consumer: [],
      platForm: [],
      thirdParty: [],
      organizations: [],
      consumerApis: [],
      platFormApis: [],
      thirdPartyApis: [],
      manufactureApis: [],
      manufacturerOrgStatus: [],
    };
  },
  methods: {
    calculateDate() {
      let current_date = new Date()
      if (new Date().getDate() == 1) {
        current_date = new Date(new Date().setDate(new Date().getDate() - 1));
      }
      let current = current_date.getFullYear();
      let month = current_date.getMonth();
      let todayDate = current_date;
      this.endDate = todayDate
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];
      var d = current_date;
      d.setDate(1);

      for (let i = 0; i < 11; i++) {
        d.setMonth(d.getMonth() - 1);
      }
      this.startDate = d
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];

      if (month == 0) {
        current = current - 1;
        this.presentYear = current.toString();
      }
      //  console.log("data",this.startDate,this.endDate)

      if (month - 1 < 0) (month = 11), (this.presentYear = current.toString());
      else {
        this.presentYear = current.toString();
      }
      let allMonths = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
    },

    //getAPI
    getApi(data, typeOrg) {
      const apiType = data;
      if (apiType.length != 0) {
        apiType.splice(0, 5).map((item, index) =>
          ApiService.get(`/organizations/${item.id}/apis`)
            .then((data) => {
              const status = alasql(
                "SELECT COLUMN DISTINCT latestStatus FROM ?",
                [data.data.data.api_list]
              );
              const lastUpdatedTime = alasql(
                "SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC",
                [data.data.data.api_list]
              );

              if (status.length > 1) {
                status.splice(0, status.length, "partial");
              } else if (status[0] === undefined) {
                status.splice(0, status.length, "NA");
              }
              if (typeOrg === "M") {
                this.manufactureApis.push({
                  name: item.name,
                  latestStatus: status[0],
                  lastStatusChangeDate:
                    lastUpdatedTime[0] != undefined
                      ? new Date(lastUpdatedTime[0])
                      : "Not Availabel",
                });
              }
              if (typeOrg === "C") {
                this.consumerApis.push({
                  name: item.name,
                  latestStatus: status[0],
                  lastStatusChangeDate:
                    lastUpdatedTime[0] != undefined
                      ? new Date(lastUpdatedTime[0])
                      : "Not Availabel",
                });
              }
              if (typeOrg === "P") {
                this.platFormApis.push({
                  name: item.name,
                  latestStatus: status[0],
                  lastStatusChangeDate:
                    lastUpdatedTime[0] != undefined
                      ? new Date(lastUpdatedTime[0])
                      : "Not Availabel",
                });
              }
              if (typeOrg === "T") {
                this.thirdPartyApis.push({
                  name: item.name,
                  latestStatus: status[0],
                  lastStatusChangeDate:
                    lastUpdatedTime[0] != undefined
                      ? new Date(lastUpdatedTime[0])
                      : "Not Availabel",
                });
              }
            })
            .catch((error) => {
              console.log("Error IN manufacture APi", error);
            })
        );
      }
      // apiType.splice(0, 5).map((item, index) => ApiService.get(`/organizations/${item.id}/apis`)
      //   .then((data) => {

      //     const status = alasql('SELECT COLUMN DISTINCT latestStatus FROM ?', [data.data.data.api_list]);
      //     const lastUpdatedTime = alasql('SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC', [data.data.data.api_list]);
      //     console.log("Ala SQL", status, data, lastUpdatedTime, data.data.data.api_list);
      //     if (status.length > 1) {
      //       status.splice(0, status.length, "partial")
      //     }
      //     this.manufactureApis.push({ name: item.name, latestStatus: status[0], lastStatusChangeDate: new Date(lastUpdatedTime[0]) })

      //   })
      //   .catch((error) => {
      //     console.log("Error IN manufacture APi", error)
      //   }))
    },

    //getOrganizations Method
    getOrganizations() {
      ApiService.get("/organizations")
        .then((data) => {
          this.organization = data.data.data.organization_list;
          this.getApi(
            this.organization.filter((item) => item.organizationType === "M"),
            "M"
          );
          this.getApi(
            this.organization.filter((item) => item.organizationType === "C"),
            "C"
          );
          this.getApi(
            this.organization.filter((item) => item.organizationType === "P"),
            "P"
          );
          this.getApi(
            this.organization.filter((item) => item.organizationType === "T"),
            "T"
          );
          let testData = alasql("SELECT * FROM ? WHERE latestStatus ==?", [
            this.manufactureApis,
            "Down",
          ]);
        })
        .catch((error) => {
          console.log("Error In side Organization", error);
        });
    },

    display(data) {
      this.ApiData = JSON.parse(JSON.stringify(data));

      this.render = true;
      if (this.ApiData.length == 0) {
        ElNotification({
          title: "Warning",
          message: "No Records Found!",
          type: "warning",
          duration: 2000,
          position: "top-right",
        });
      }
    },
    getManufacturerApi() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/manufacturer/apimonitoring/data?organizationId=" +
          this.globalData.organizationId +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate
      )
        .then((data) => {
          this.display(data.data.data);
          loading.close();
        })
        .catch((error) => {
          loading.close();
          this.render = true;
          this.errorPart = JSON.stringify(
            error.response.data["errors"][0]["errorMessage"]
          );
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          console.log(error);
        });
    },
    getConsumerApi() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/consumer/apimonitoring/data?organizationId=" +
          this.globalData.organizationId +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate
      )
        .then((data) => {
          console.log("Consumer Data", data);
          this.display(data.data.data);
          loading.close();
        })
        .catch((error) => {
          this.render = true;

          loading.close();
          this.errorPart = JSON.stringify(
            error.response.data["errors"][0]["errorMessage"]
          );
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
  },
  mounted() {
    // this.getOrganizations();
    this.calculateDate();
    if (this.getOrgRole() == "manufacturer" || this.getOrgRole() == "1SB") {
      this.getManufacturerApi();
    } else if (this.getOrgRole() == "consumer") {
      this.getConsumerApi();
    }
  },
  setup() {
    onMounted(() => {
      let user = store.getters.currentUser;
      if (Object.keys(user).length == 0) {
        router.push({ path: "/sign-in" });
      }
      setCurrentPageTitle("Dashboard");
    });
  },
});
