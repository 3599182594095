
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  reactive,
} from "vue";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import alasql from "alasql";

import Datatable from "@/components/kt-datatable/Datatable.vue";
export default defineComponent({
  name: "PlatformInternalAPI",
  mixins: [Globals],
  components: {
    Datatable,
  },
  props: {
    widgetClasses: String,
    color: String,
    image: String,
    title: String,
    date: String,
    progress: String,
    manufactureList: [] as any,
  },
  data() {
    return {
      ApiData: [],
      oneSBData: [],
      orgData: [] as any,
      page: 1,
      totalCount: 1,
      x: "" as any,
    };
  },
  methods: {
    calculateLatestStatus(latestStatus){
      if (latestStatus.includes("up") && latestStatus.includes("down")){
        return true;
      }
      return false;
    },
    fun(x) {
      this.x = x;
    },

    display(data) {
      this.ApiData = JSON.parse(JSON.stringify(data));
      for (let i of Object.keys(this.ApiData["data"])) {
        if (
          Object.prototype.hasOwnProperty.call(this.ApiData, "data") &&
          this.ApiData["data"][i] != null
        ) {
          // condition if data is not null
          this.orgData.push(this.ApiData["data"][i]);
        }
      }
    },
    getOrganization() {
      ApiService.get(`/organizations/1SB-PFM/apis?featureMode=dashboard_api_status`)
      
        .then((data) => {
          const Test = alasql(
            "SELECT apiGroup AS name, GROUP_CONCAT(DISTINCT latestStatus) AS latestStatus, MAX(lastStatusChangeDate) AS lastStatusChangeDate FROM ? GROUP BY apiGroup",
            [data.data.data.api_list]
          );
          console.log("Test-->>",Test);

          this.oneSBData = [...Test];
        })
        .catch((error) => {
          console.log("Error in side", error);
        });
    },
    // getOrganizations() {
    //   if(this.totalCount!= this.oneSBData.length){
    //     ApiService.get(`/organizations?organizationType=P&pageNumber=${this.page}&pageSize=5`)
    //     .then((data) => {
    //       console.log("Manufacture", data.data.data.organization_list)
    //       this.totalCount = data.data.data.total_count;
    //       return data.data.data.organization_list.map((item, index) =>
    //       ApiService.get(`/organizations/${item.id}/apis?featureMode=dashboard_api_status`)

    //         .then((data) => {

    //           const status = alasql('SELECT COLUMN DISTINCT latestStatus FROM ?', [data.data.data.api_list]);
    //           const lastUpdatedTime = alasql('SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC', [data.data.data.api_list]);

    //           console.log("Ala Sagar SQL", status, data, lastUpdatedTime, data.data.data.api_list);
    //           if (status.length > 1) {
    //             status.splice(0, status.length, "partial")
    //           } else if (status[0] === undefined) {
    //             status.splice(0, status.length, "NA")

    //           }
    //           if(item.id==="1SB-PFM"){
    //             this.oneSBData.push({ name: item.name, latestStatus: status[0], lastStatusChangeDate: lastUpdatedTime[0] != undefined ? new Date(lastUpdatedTime[0]) : "Not Availabel" })
    //            console.log("Sagar", item.name, status[0], lastUpdatedTime[0])
    //            console.log("Manufacturer Updated", this.oneSBData);
    //           }

    //         })
    //         .catch((error) => {
    //           console.log("Error IN manufacture APi", error)
    //         }))

    //     }).
    //     catch((error) => {
    //       console.log("Error In side Organization", error)
    //     })
    //   }

    //     this.page+=1;
    // },
    handleScroll(e) {
      const element = document.getElementById("oneSBCard");
      var style = window.getComputedStyle(element, null);
      const heightElement = style.getPropertyValue("height");
      if (
        parseInt(style.getPropertyValue("height").split("p")[0]) +
          element.scrollTop ===
        element.scrollHeight
      ) {
        this.getOrganization();
      }
    },

    // getManuData() {
    //   ApiService.get("/manufacturerStatus/Dashboard?organizationId=" + this.globalData.organizationId)
    //     .then((data) => {
    //       this.display(data.data)
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    // }
  },

  destroy() {
    const element = document.getElementById("oneSBCard");
    element.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getOrganization();
    // this.getManuData();
    const element = document.getElementById("oneSBCard");
    element.addEventListener("scroll", this.handleScroll);
  },

  setup(props) {
    // const text = document

    const scrollComponent = ref(null);
    const tableHeaderConsumer = ref([
      {
        name: "1SB Platform API Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Status",
        key: "latestStatus",
        sortable: true,
      },
    ]);
    const tableHeaderManufacturer = ref([
      {
        name: "1SB Platform API Name",
        key: "name",
        sortable: true,
      },

      {
        name: "Api Status",
        key: "latestStatus",
        sortable: true,
      },
    ]);
    return {
      tableHeaderConsumer,
      tableHeaderManufacturer,
      scrollComponent,
    };
  },
});
