
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import alasql from "alasql";
import { todayDate } from "@/core/data/events";
import { date } from "yup/lib/locale";
import MaxMinWidget7 from "./MaxMinWidget7.vue";

export default defineComponent({
    name: "widget-7",
    mixins: [Globals],
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        apiData: Array
    },
    data() {
        return {
            render: false,
            startDate: "",
            today: "",
            APIData: this.apiData,
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 180,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: true
                    }
                },
                grid: {
                    show: false, // you can either change hear to disable all grids
                    xaxis: {
                        lines: {
                            show: false //or just here to disable only x axis grids
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false //or just here to disable only y axis
                        }
                    },
                },
                responsive: [{
                        breakpoint: 480,
                        options: {
                        // legend: {
                        //   // position: 'bottom',
                        //   offsetX: -10,
                        //   offsetY: 0
                        // }
                        }
                    }],
                plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [] as any,
                    labels: {
                        trim: true,
                    }
                },
                legend: {
                    position: 'right',
                    offsetY: 40,
                    show: false
                },
                fill: {
                    colors: ['#f9d984', '#f78d5b', '#f35b72', "#f6a3b7", "#dea4cc", "#c4adc9"],
                    opacity: 1
                },
            },
            manufacturerApiCalls: [] as any,
            currentYear: "",
            currentMonth: ""
        };
    },
    methods: {
        display() {
            this.render = true;
            // this.APIData=JSON.parse(JSON.stringify(data))
            let distinctManufactureData = [];
            if (this.getOrgRole() == "manufacturer" || this.getOrgRole() == "1SB") {
                distinctManufactureData = alasql("SELECT DISTINCT [Consumer Organization] FROM ?", [
                    this.APIData,
                ]);
            }
            else if (this.getOrgRole() == "consumer") {
                distinctManufactureData = alasql("SELECT DISTINCT [Manufacturer Organization] FROM ?", [
                    this.APIData,
                ]);
            }
            let monthYearData = alasql("SELECT * FROM ? WHERE Year==? AND  Month==?", [this.APIData, Number(this.currentYear), this.currentMonth]);
            //  console.log("month",monthYearData)
            let apiCalls = [] as any;
            for (let i = 0; i < distinctManufactureData.length; i++) {
                if (this.getOrgRole() == "manufacturer" || this.getOrgRole() == "1SB") {
                    this.chartOptions.xaxis.categories.push(distinctManufactureData[i]["Consumer Organization"]);
                }
                else if (this.getOrgRole() == "consumer") {
                    this.chartOptions.xaxis.categories.push(distinctManufactureData[i]["Manufacturer Organization"]);
                }
                let apiData = [];
                if (this.getOrgRole() == "manufacturer" || this.getOrgRole() == "1SB") {
                    apiData = alasql("SELECT * FROM ? WHERE [Consumer Organization]==?", [monthYearData, distinctManufactureData[i]["Consumer Organization"]]);
                }
                else if (this.getOrgRole() == "consumer") {
                    apiData = alasql("SELECT * FROM ? WHERE [Manufacturer Organization]==?", [monthYearData, distinctManufactureData[i]["Manufacturer Organization"]]);
                }
                let calls = 0;
                for (let j = 0; j < apiData.length; j++) {
                    calls = calls + apiData[j]['Calls'];
                }
                apiCalls.push(calls);
            }
            let body = {};
            body["name"] = "API Calls";
            body["data"] = apiCalls;
            this.manufacturerApiCalls.push(body);
        },
        calculateDate() {
            let current_date = new Date();
            if (new Date().getDate() == 1) {
                current_date = new Date(new Date().setDate(new Date().getDate() - 1));
            }
            let current = (current_date).getFullYear();
            let month = (current_date).getMonth();
            this.currentYear = current.toString();
            var date = current_date;
            this.today = date.toISOString().replace("T", " ").split(".")[0].split(" ")[0];
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            this.startDate = firstDay.toISOString().replace("T", " ").split(".")[0].split(" ")[0];
            // if(month==0)
            // {
            //   current=current-1
            // this.currentYear=current.toString()
            // }
            // if((month-1)<0)
            // month=11
            // else
            // {
            // month=month-1
            // this.currentYear=current.toString()
            // }
            let array = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
            this.currentMonth = array[month];
        }
    },
    mounted() {
        this.calculateDate();
        this.display();
    },
    setup(props) {
        const color = ref(props.chartColor);
        const labelColor = getCSSVariableValue("--bs-" + "gray-800");
        const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
        const baseColor = getCSSVariableValue("--bs-" + color.value);
        const lightColor = getCSSVariableValue("--bs-light-" + color.value);
    },
    components: { MaxMinWidget7 }
});
